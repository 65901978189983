import { Box, Container, Grid, Typography } from '@mui/material';

import {
	Maybe,
	NavigationLink,
	Page,
	AdFooterNavigationGroupsCollection,
	AdFooterSocialNetworksCollection,
	AdFooterAbout,
} from '@/types/generated';
import TAGLink from '@/components/TAGLink';
import TAGTextFieldInput from '@/components/TAGTextFieldInput';
import RichText, { RichTextContent } from '@/components/RichText';
import TAGButton from '@/components/TAGButton';
import { SocialNetworkIcons } from '@/components/FooterAlt/FooterAltSocialLinks';
import { useAppContext, useFacilityContext, useOfficeLocationContext } from '@/context';

import { resolveLinkTitle, resolveLinkUrl } from '../FooterLite.helpers';
import { getAboutWrapperStyles } from '../FooterLite.styles';

import {
	footerContainerStyles,
	getSubscribeInputStyles,
	getSubscribeInputWrapperStyles,
} from './FootLiteContent.styles';
interface IFooterLiteContentProps {
	isSmallDevice: boolean;
	navigationGroups?: Maybe<AdFooterNavigationGroupsCollection>;
	socialNetworks?: Maybe<AdFooterSocialNetworksCollection>;
	footerAbout?: Maybe<AdFooterAbout>;
	withSubscriptionForm: boolean;
}

const FooterLiteContent = ({
	isSmallDevice,
	navigationGroups,
	socialNetworks,
	footerAbout,
	withSubscriptionForm = false,
}: IFooterLiteContentProps) => {
	const { config } = useAppContext();
	const { officeInfo } = useFacilityContext();
	const { officeInfo: officeLocationInfo } = useOfficeLocationContext();
	const contextData = {
		config,
		officeInfo,
		officeLocationInfo,
	};

	const FooterNavigationContent = () => {
		const groupItems = navigationGroups?.items;
		if (!groupItems) return null;

		return (
			<>
				{groupItems.map((group) => {
					return (
						group && (
							<Box
								display="flex"
								flexDirection="column"
								key={group?.sys.id}
								data-test-id={`section_footerlite_navigation_content_${group.sys.id}`}
								rowGap={1.5}
							>
								{group?.navigationGroupTitle ? (
									<Typography
										variant="header4"
										title={group.navigationGroupTitle}
										color="text.interactive"
									>
										{group.navigationGroupTitle}
									</Typography>
								) : group?.navigationGroupLink ? (
									<TAGLink
										dataTestId={`link_group_title_${group.sys.id}`}
										href={resolveLinkUrl(group.navigationGroupLink, contextData)}
										noLinkStyle
										analyticsContext={group.navigationGroupLink.analyticsContext}
									>
										<Typography
											variant="header4"
											title={resolveLinkTitle(group.navigationGroupLink)}
											color="text.interactive"
										>
											{resolveLinkTitle(group.navigationGroupLink)}
										</Typography>
									</TAGLink>
								) : null}

								{group.navigationLinksForGroupCollection?.items.map(
									(navItem: Maybe<NavigationLink | Page> | undefined) => {
										return (
											navItem && (
												<TAGLink
													key={navItem.sys.id}
													href={resolveLinkUrl(navItem, contextData)}
													openInNewTab={(navItem as NavigationLink)?.openInNewTab || false}
													dataTestId={`link_for_group_${navItem.sys.id}`}
													noLinkStyle
													linkSx={{
														paddingRight: isSmallDevice ? '0rem' : '2rem',
													}}
													analyticsContext={navItem.analyticsContext}
												>
													<Typography
														color="text.interactive"
														variant="bodyLargeBook"
														title={resolveLinkTitle(navItem)}
													>
														{resolveLinkTitle(navItem)}
													</Typography>
												</TAGLink>
											)
										);
									}
								)}
							</Box>
						)
					);
				})}
			</>
		);
	};

	const SubscribeForm = () => {
		const inputProps = { 'data-test-id': 'footerlite_subscribe_input', disableUnderline: true };
		return (
			<Grid sx={{ display: 'flex' }} flexDirection={isSmallDevice ? 'column' : 'row'} pb={2} gap={1}>
				<TAGTextFieldInput
					fieldPlaceHolder="Enter Email"
					wrapperSx={getSubscribeInputWrapperStyles}
					inputSx={getSubscribeInputStyles}
					inputProps={inputProps}
				/>
				<TAGButton size="XS" variant="primaryDefault" dataTestId="button_schedule_desktop">
					Button
				</TAGButton>
			</Grid>
		);
	};

	const AboutRichTextSection = () => {
		if (!footerAbout || !footerAbout?.json) return null;
		const footerRichText = footerAbout.json as RichTextContent;
		return (
			<Box data-test-id="section_footerlite_about">
				<Container disableGutters sx={{ ...getAboutWrapperStyles(isSmallDevice) }}>
					<RichText
						content={footerRichText}
						docProps={{
							color: 'text.primary',
							variant: 'bodyMediumBook',
							textAlign: 'start',
						}}
					/>
				</Container>
			</Box>
		);
	};

	const SocialMediaLinks = () => {
		if (!socialNetworks) return null;

		const socNetworkItems = socialNetworks?.items as Page[];

		if (!socNetworkItems) return null;

		return (
			<Box sx={footerContainerStyles} data-test-id="section_footerlite_social_media_links" py={2}>
				<Box display="flex" gap={2} justifyContent="start" alignItems="start">
					<SocialNetworkIcons socNetworkItems={socNetworkItems} />
				</Box>
			</Box>
		);
	};

	return (
		<Box
			data-test-id="section_footerlite_content"
			flexDirection={isSmallDevice ? 'column' : 'row'}
			display={'flex'}
			justifyContent={'space-between'}
			py={2}
			gap={2.5}
		>
			<FooterNavigationContent />
			<Grid>
				{withSubscriptionForm && <SubscribeForm />}
				<AboutRichTextSection />
				<SocialMediaLinks />
			</Grid>
		</Box>
	);
};

export default FooterLiteContent;
